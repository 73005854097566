import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image_mobile = require("../../../assets/img/blogs/building_itsm_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/building_itsm_blog_header.png");
const section_1 = require("../../../assets/img/blogs/building_itsm_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/building_itsm_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/building_itsm_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/building_itsm_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/building_itsm_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/building_itsm_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/building_itsm_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/building_itsm_blog_img_8.png");
const section_9 = require("../../../assets/img/blogs/building_itsm_blog_img_9.png");
const section_10 = require("../../../assets/img/blogs/building_itsm_blog_img_10.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title=" ITSM Ecosystem: MS Teams, Gen AI, and the Human Touch"
        description="Want to build a collaborative ITSM ecosystem? Workativ can help by improving communications, empowering users, and reducing MTTR."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt building_itsm_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-black line-height-18">
                    Building a Collaborative ITSM Ecosystem: MS Teams, Gen AI,
                    and Human Touch
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-employee color-black">
                    Building a Collaborative
                    <br /> ITSM Ecosystem:
                    <br /> MS Teams, Gen AI, <br />
                    and Human Touch
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Why Is ITSM critical for IT and non-IT workflows?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why look for a collaborative ITSM ecosystem?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. What Is a collaborative ITSM ecosystem?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. How to use MS teams, GenAI, and human touch for a
                  collaborative ITSM ecosystem
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Benefits of the integration power of collaborative ITSM
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Embrace AI-driven collaborative ITSM with Workativ.
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                The IT department in your company keeps your operations running
                by taking care of the needs of your varied stakeholders—leaders,
                management, internal employees, helpdesk support, customers,
                etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A minor issue in your IT assets means a disconnected experience,
                creating ripple effects across the value chains.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A glaring fact is that IT issues will accelerate at a massive
                pace.{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  42% of respondents&nbsp;
                </span>
                in a&nbsp;
                <a href="https://www.spiceworks.com/tech/it-strategy/guest-article/avoiding-it-outages/">
                  survey
                </a>{" "}
                conducted by BigPanda pointed out digital transformation and
                increased dependency give rise to growing IT outages.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The best driving force to combat IT failure and facilitate IT
                resilience and business continuity is your ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, a collaborative ITSM ecosystem can enable comprehensive
                service delivery and user experience. This ecosystem enables
                end-to-end communications and coordination across value chains,
                self-service for real-time issue mitigation,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/boost-agent-efficiency-shared-inbox-integration">
                  agent efficiency for critical problems,
                </a>{" "}
                enhanced customer experiences, and business success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a collaborative ITSM ecosystem, your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  service desk
                </a>{" "}
                can reimagine every aspect of IT services and user experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why Is ITSM critical for IT and non-IT workflows?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="challenges of contact center without Generative AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  ITSM
                </a>{" "}
                provides a set of principles for IT leaders to maintain IT
                services efficiently. It offers tools and features to streamline
                workflows for communications that help with ticket creation,
                issue resolution, and restoration of the services effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The core idea behind ITSM is to maintain IT resilience by
                reducing MTTR and eliminating the impact of issues in real time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Not just IT support but also non-IT workflows can gain
                significant benefits from ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR, finance, marketing, and other processes can use ITSM to
                design workflows for various use cases and drive success.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why look for a collaborative ITSM ecosystem?
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Why need a Collaborative ITSM ecosystem?"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                ITSM aims to drive business success by allowing self-service,
                communication and coordination, and agent efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, information silo is a common challenge for most ITSM
                ecosystems.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Users continue to use their preferred medium of communication
                channels to raise issues—the lack of a centralized information
                delivery channel makes it difficult to get visibility into
                real-time information in one place.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It takes time to capture information from disparate channels,
                make sense of the actual cause of problems, assign the right
                team to handle an issue, and ultimately mitigate it.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Without integrated data points, preparing solid root cause
                analysis or RCA reports and analytics is complicated, resulting
                in IT leaders struggling to suggest effective mitigation plans
                in advance and prevent repetitive issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If a company continues to work with an ITSM system in silo, it
                can have other challenges for its people.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18">
                  Users use fragmented tools to communicate their IT issues.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Ticket creation is challenging inside an embedded module of an
                  ITSM platform.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  The self-service module is ineffective as knowledge bases keep
                  outdated information.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  New cases or history remains in a silo, sending agents
                  perplexed when it comes to offering real-time threat
                  mitigation actions for critical issues.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  collaborative ITSM ecosystem
                </span>{" "}
                helps remove all of these bottlenecks. It enables IT leaders to
                provide a streamlined process to maintain the overall health of
                an{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  ITSM
                </a>{" "}
                and help keep it efficient for everyone involved.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What Is a collaborative ITSM ecosystem?
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                An ITSM is collaborative when it provides end-to-end
                collaboration and coordination to enable real-time
                problem-solving by empowering human agents and users with
                AI-powered innovation, automation, and powerful integrations
                like MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A collaborative ITSM eliminates the need to toggle between
                disparate tools for raising a ticket, makes it easy to find
                appropriate knowledge articles to solve common problems
                autonomously and boost agents’ efficiency to mitigate critical
                issues in minimal time to enhance user experiences.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                When plugged into{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  MS Teams, Workativ
                </a>{" "}
                brings a collaborative approach to the ITSM ecosystem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Its{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ms-teams-bot-for-itsm">
                  conversational AI that integrates with MS Teams
                </a>{" "}
                brings the power of Generative AI to enhance search performance,
                simplify knowledge discovery, and reduce MTTR for real-time
                problem resolution for everyone.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to use MS teams, GenAI, and human touch for a collaborative
                ITSM ecosystem
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt=" Build a collaborative ITSM ecosystem with MS Teams, GenAI and human creativity"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With an ITSM combining the power of MS Teams, GenAI, and human
                creativity, ITSM drives workflows towards efficient{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  internal support,
                </a>{" "}
                customer support, and successful business operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s know how to turn your ITSM into a collaborative support
                platform with Workativ conversational AI.
              </p>

              <h3 className="font-section-sub-header-small">
                Manage IT issues and requests.
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem for IT issues and requests management"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By combining{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  Generative AI inside a conversational AI
                </a>{" "}
                for MS Teams bot, Workativ builds a highly efficient
                collaborative ITSM infrastructure that reduces human workloads
                in various ways.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI-powered search feature for a conversational AI bot
                reduces the time it takes for users to find relevant information
                about any issues instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ helps ITSM leaders build large language models with
                cases relevant across the industry and domain-specific use
                cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Applying the world of knowledge, including specific industry
                data, Generative AI in Workativ conversational AI makes it easy
                to retrieve information specific to IT and non-IT scenarios in
                consolidated forms along with citations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is easy for users to leverage only relevant information to
                solve a specific issue instead of going through an entire
                article and increasing MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At a time when citations ensure the veracity of information,
                users become more confident in using self-service for common IT
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This helps agents avoid addressing repetitive issues and instead
                use creativity to solve critical issues for higher tiers in the
                ITSM.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt=" Rapid generation of agent responses on a collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When Workativ allows agents to use a{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  shared live inbox,
                </a>{" "}
                it brings all agent interactions into one screen, enabling
                everyone to leverage real-time updates of issues. As a result,
                if an agent struggles to offer the proper recommendations for a
                specific case, a more skilled or expert agent can arrive to help
                and reduce MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Another effective use case of a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/boost-agent-efficiency-shared-inbox-integration">
                  shared live inbox that simplifies interaction for agents
                </a>{" "}
                and accelerates crisis response is LLM-powered recommendations
                for chat. Using internal knowledge bases, Generative AI can
                recommend suitable AI-powered suggestions to reduce the time to
                craft a response yet communicate effectively to mitigate an
                issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ultimately, faster crisis communication and response help reduce
                MTTR and maintain ITSM SLA.
              </p>
              <h3 className="font-section-sub-header-small">
                Enable collaboration and knowledge sharing.
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem for knowledge sharing"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                MS Teams is always known for its powerful tools and features to
                help build a unified workspace where every employee loves
                working.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Keeping in mind user preferences, Workativ allows them to use
                Teams to communicate and coordinate over familiar and
                complicated workplace issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Internal employees can use Teams to autonomously address issues,
                including password reset, account unlock, user provisions,
                software installs, onboarding, PTO inquiry, and so on, by
                retrieving information at scale.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The key essence here is that users can leverage Teams to get and
                share information to help each other.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To keep agents efficient or the shift-left process effective,
                Workativ allows early-stage or novice agents to leverage the MS
                Teams bot to capture essential information by interacting with
                senior teams in a collaborative discussion thread and gaining
                valuable information to solve a critical problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On the other hand, agents can leverage{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  {" "}
                  shared live inbox
                </a>{" "}
                in MS Teams bot to keep track of ongoing issues—how many tickets
                are open, in queue, and closed.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In addition to having the latest ticket updates, agents can use
                the ‘mention’ or @ feature to tag someone with high-level
                expertise to seek instant help with knowledge articles or
                valuable tips for mitigation.
              </p>

              <h3 className="font-section-sub-header-small">
                Build user-centric ITSM
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="User-centric ITSM on a collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Workativ conversational AI{" "}
                </span>
                is a no-code intuitive chatbot builder that turns use cases into
                workflows for an ITSM platform and brings them directly to your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ms-teams-bot-workplace-support-bot">
                  MS Teams chatbot
                </a>{" "}
                through a connector.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is a familiar interface that can easily serve as a
                self-service interface for your users to handle ITSM-related
                workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Instead of having the nuances of working inside a self-service
                platform of an ITSM tool, MS Teams delivers a user-friendly
                interface to find knowledge quickly, raise a query, and ask
                questions about ticket status all within MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By integrating large language model capabilities with
                conversational AI for the MS Team bot, users and agents can find
                information at scale and reduce the length of time needed for
                problem resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Workativ, users gain the benefits of Generative AI and MS
                Teams that boost interactions, efficiencies, and user
                satisfaction.
              </p>
              <h3 className="font-section-sub-header-small">
                Leverage predictive analytics and reporting for KPI improvement.
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Find service desk KPIs on a collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With legacy ITSM infrastructure, leaders can have data, but more
                is needed to create comprehensive KPIs and drive towards a
                proactive ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A usual scene with legacy ITSM is that the same issue can be too
                repetitive to take away fair enough time from service desk
                agents, ultimately impacting SLA compliance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When you leverage Workativ, you can harness powerful Generative
                AI capabilities known for capturing comprehensive datasets,
                which further help generate key performance indicators such as
                user experience, trends of issues, tickets sent, tickets
                resolved, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you keep using Workativ to handle ITSM issues, you can
                leverage{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  advanced insights into the KPIs
                </a>{" "}
                you build and analyze the performance of your ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if the KPI for CX is slightly impacted for the use
                case password reset issues, you can use datasets to redesign
                your workflow automation and improve service requests as well as
                the future performance of ITSM.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of the integration power of collaborative ITSM
              </h2>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem benefits"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If your ITSM is disparate, your teams and customers can have a
                disconnected user experience. A collaborative ITSM eliminates
                existing challenges with a legacy ITSM and builds a proactive
                ITSM, which ultimately helps you gain multiple benefits.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    The resolution of critical and common issues
                  </span>{" "}
                  is very fast with a collaborative ITSM ecosystem that Wokrativ
                  helps you build. Users can use MS Teams to leverage Generative
                  AI-powered self-service to solve everyday problems
                  autonomously. On the other hand, agents can use a shared live
                  inbox to manage tickets and use rich data to handle critical
                  issues.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Workativ helps increase the{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    productivity and efficiency of the service desk
                  </span>{" "}
                  dramatically. Users no longer need to find information
                  haphazardly. Generative AI provides relevant information from
                  your knowledge bases in a consolidated manner. Also, agents
                  can use AI-powered suggestions to send responses and save
                  time.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Workativ enables{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    seamless communications and coordination
                  </span>{" "}
                  for users with MS Teams. Users can communicate requests, get
                  updates, and fix critical issues with agents’ help. Also,
                  agents can double their creativity to handle critical issues
                  using Generative AI recommendations and suggestions.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Workativ ensures ITSM users can have{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    an improved user experience
                  </span>{" "}
                  by reducing the wait times for common and critical problems.
                  Generative AI search improves knowledge discovery, reducing
                  the time to leverage knowledge and address a domain-specific
                  issue, allowing users to come back to self-service and
                  increase adoption, which ultimately lowers agent workloads and
                  helps build a successful ITSM.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Embrace AI-driven collaborative ITSM with Workativ.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Every company has some sort of IT assets. The bigger the IT
                infrastructure, the bigger the problem is. SMBs can also have
                pain points for the growing IT challenges. However, non-IT
                workflows are pretty concerning for ITSM leaders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Legacy ITSM needs more features and automation to provide a
                collaborative infrastructure for ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ, with its conversational AI for MS Teams, provides a
                platform that elevates human creativity to tackle more
                challenging issues using Generative AI properties.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By improving communications, empowering human agents and users,
                and reducing MTTR, Workativ gives you the true essence of
                collaborative ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to leverage Workativ for a collaborative ITSM
                ecosystem,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Why Is ITSM critical for IT and non-IT workflows?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why look for a collaborative ITSM ecosystem?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. What Is a collaborative ITSM ecosystem?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. How to use MS teams, GenAI, and human touch for a
                    collaborative ITSM ecosystem
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Benefits of the integration power of collaborative ITSM
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Embrace AI-driven collaborative ITSM with Workativ.
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                The IT department in your company keeps your operations running
                by taking care of the needs of your varied stakeholders—leaders,
                management, internal employees, helpdesk support, customers,
                etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A minor issue in your IT assets means a disconnected experience,
                creating ripple effects across the value chains.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A glaring fact is that IT issues will accelerate at a massive
                pace.{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  42% of respondents&nbsp;
                </span>
                in a&nbsp;
                <a href="https://www.spiceworks.com/tech/it-strategy/guest-article/avoiding-it-outages/">
                  survey
                </a>{" "}
                conducted by BigPanda pointed out digital transformation and
                increased dependency give rise to growing IT outages.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The best driving force to combat IT failure and facilitate IT
                resilience and business continuity is your ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, a collaborative ITSM ecosystem can enable comprehensive
                service delivery and user experience. This ecosystem enables
                end-to-end communications and coordination across value chains,
                self-service for real-time issue mitigation,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/boost-agent-efficiency-shared-inbox-integration">
                  agent efficiency for critical problems,
                </a>{" "}
                enhanced customer experiences, and business success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a collaborative ITSM ecosystem, your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  service desk
                </a>{" "}
                can reimagine every aspect of IT services and user experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why Is ITSM critical for IT and non-IT workflows?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="challenges of contact center without Generative AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  ITSM
                </a>{" "}
                provides a set of principles for IT leaders to maintain IT
                services efficiently. It offers tools and features to streamline
                workflows for communications that help with ticket creation,
                issue resolution, and restoration of the services effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The core idea behind ITSM is to maintain IT resilience by
                reducing MTTR and eliminating the impact of issues in real time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Not just IT support but also non-IT workflows can gain
                significant benefits from ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR, finance, marketing, and other processes can use ITSM to
                design workflows for various use cases and drive success.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why look for a collaborative ITSM ecosystem?
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Why need a Collaborative ITSM ecosystem?"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                ITSM aims to drive business success by allowing self-service,
                communication and coordination, and agent efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, information silo is a common challenge for most ITSM
                ecosystems.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Users continue to use their preferred medium of communication
                channels to raise issues—the lack of a centralized information
                delivery channel makes it difficult to get visibility into
                real-time information in one place.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It takes time to capture information from disparate channels,
                make sense of the actual cause of problems, assign the right
                team to handle an issue, and ultimately mitigate it.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Without integrated data points, preparing solid root cause
                analysis or RCA reports and analytics is complicated, resulting
                in IT leaders struggling to suggest effective mitigation plans
                in advance and prevent repetitive issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If a company continues to work with an ITSM system in silo, it
                can have other challenges for its people.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18">
                  Users use fragmented tools to communicate their IT issues.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Ticket creation is challenging inside an embedded module of an
                  ITSM platform.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  The self-service module is ineffective as knowledge bases keep
                  outdated information.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  New cases or history remains in a silo, sending agents
                  perplexed when it comes to offering real-time threat
                  mitigation actions for critical issues.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  collaborative ITSM ecosystem
                </span>{" "}
                helps remove all of these bottlenecks. It enables IT leaders to
                provide a streamlined process to maintain the overall health of
                an{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  ITSM
                </a>{" "}
                and help keep it efficient for everyone involved.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What Is a collaborative ITSM ecosystem?
              </h2>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                An ITSM is collaborative when it provides end-to-end
                collaboration and coordination to enable real-time
                problem-solving by empowering human agents and users with
                AI-powered innovation, automation, and powerful integrations
                like MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A collaborative ITSM eliminates the need to toggle between
                disparate tools for raising a ticket, makes it easy to find
                appropriate knowledge articles to solve common problems
                autonomously and boost agents’ efficiency to mitigate critical
                issues in minimal time to enhance user experiences.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                When plugged into{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  MS Teams, Workativ
                </a>{" "}
                brings a collaborative approach to the ITSM ecosystem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Its{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ms-teams-bot-for-itsm">
                  conversational AI that integrates with MS Teams
                </a>{" "}
                brings the power of Generative AI to enhance search performance,
                simplify knowledge discovery, and reduce MTTR for real-time
                problem resolution for everyone.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to use MS teams, GenAI, and human touch for a collaborative
                ITSM ecosystem
              </h2>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt=" Build a collaborative ITSM ecosystem with MS Teams, GenAI and human creativity"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With an ITSM combining the power of MS Teams, GenAI, and human
                creativity, ITSM drives workflows towards efficient{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  internal support,
                </a>{" "}
                customer support, and successful business operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s know how to turn your ITSM into a collaborative support
                platform with Workativ conversational AI.
              </p>

              <h3 className="font-section-sub-header-small">
                Manage IT issues and requests.
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem for IT issues and requests management"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By combining{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                  Generative AI inside a conversational AI
                </a>{" "}
                for MS Teams bot, Workativ builds a highly efficient
                collaborative ITSM infrastructure that reduces human workloads
                in various ways.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI-powered search feature for a conversational AI bot
                reduces the time it takes for users to find relevant information
                about any issues instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ helps ITSM leaders build large language models with
                cases relevant across the industry and domain-specific use
                cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Applying the world of knowledge, including specific industry
                data, Generative AI in Workativ conversational AI makes it easy
                to retrieve information specific to IT and non-IT scenarios in
                consolidated forms along with citations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is easy for users to leverage only relevant information to
                solve a specific issue instead of going through an entire
                article and increasing MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At a time when citations ensure the veracity of information,
                users become more confident in using self-service for common IT
                issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This helps agents avoid addressing repetitive issues and instead
                use creativity to solve critical issues for higher tiers in the
                ITSM.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt=" Rapid generation of agent responses on a collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When Workativ allows agents to use a{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  shared live inbox,
                </a>{" "}
                it brings all agent interactions into one screen, enabling
                everyone to leverage real-time updates of issues. As a result,
                if an agent struggles to offer the proper recommendations for a
                specific case, a more skilled or expert agent can arrive to help
                and reduce MTTR.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Another effective use case of a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/boost-agent-efficiency-shared-inbox-integration">
                  shared live inbox that simplifies interaction for agents
                </a>{" "}
                and accelerates crisis response is LLM-powered recommendations
                for chat. Using internal knowledge bases, Generative AI can
                recommend suitable AI-powered suggestions to reduce the time to
                craft a response yet communicate effectively to mitigate an
                issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Ultimately, faster crisis communication and response help reduce
                MTTR and maintain ITSM SLA.
              </p>
              <h3 className="font-section-sub-header-small">
                Enable collaboration and knowledge sharing.
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem for knowledge sharing"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                MS Teams is always known for its powerful tools and features to
                help build a unified workspace where every employee loves
                working.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Keeping in mind user preferences, Workativ allows them to use
                Teams to communicate and coordinate over familiar and
                complicated workplace issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Internal employees can use Teams to autonomously address issues,
                including password reset, account unlock, user provisions,
                software installs, onboarding, PTO inquiry, and so on, by
                retrieving information at scale.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The key essence here is that users can leverage Teams to get and
                share information to help each other.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To keep agents efficient or the shift-left process effective,
                Workativ allows early-stage or novice agents to leverage the MS
                Teams bot to capture essential information by interacting with
                senior teams in a collaborative discussion thread and gaining
                valuable information to solve a critical problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On the other hand, agents can leverage{" "}
                <a href="https://workativ.com/conversational-ai-platform/shared-inbox">
                  {" "}
                  shared live inbox
                </a>{" "}
                in MS Teams bot to keep track of ongoing issues—how many tickets
                are open, in queue, and closed.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In addition to having the latest ticket updates, agents can use
                the ‘mention’ or @ feature to tag someone with high-level
                expertise to seek instant help with knowledge articles or
                valuable tips for mitigation.
              </p>

              <h3 className="font-section-sub-header-small">
                Build user-centric ITSM
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="User-centric ITSM on a collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Workativ conversational AI{" "}
                </span>
                is a no-code intuitive chatbot builder that turns use cases into
                workflows for an ITSM platform and brings them directly to your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ms-teams-bot-workplace-support-bot">
                  MS Teams chatbot
                </a>{" "}
                through a connector.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is a familiar interface that can easily serve as a
                self-service interface for your users to handle ITSM-related
                workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Instead of having the nuances of working inside a self-service
                platform of an ITSM tool, MS Teams delivers a user-friendly
                interface to find knowledge quickly, raise a query, and ask
                questions about ticket status all within MS Teams.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                By integrating large language model capabilities with
                conversational AI for the MS Team bot, users and agents can find
                information at scale and reduce the length of time needed for
                problem resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With Workativ, users gain the benefits of Generative AI and MS
                Teams that boost interactions, efficiencies, and user
                satisfaction.
              </p>
              <h3 className="font-section-sub-header-small">
                Leverage predictive analytics and reporting for KPI improvement.
              </h3>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Find service desk KPIs on a collaborative ITSM ecosystem"
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With legacy ITSM infrastructure, leaders can have data, but more
                is needed to create comprehensive KPIs and drive towards a
                proactive ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                A usual scene with legacy ITSM is that the same issue can be too
                repetitive to take away fair enough time from service desk
                agents, ultimately impacting SLA compliance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                When you leverage Workativ, you can harness powerful Generative
                AI capabilities known for capturing comprehensive datasets,
                which further help generate key performance indicators such as
                user experience, trends of issues, tickets sent, tickets
                resolved, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you keep using Workativ to handle ITSM issues, you can
                leverage{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-analytics-performance">
                  advanced insights into the KPIs
                </a>{" "}
                you build and analyze the performance of your ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, if the KPI for CX is slightly impacted for the use
                case password reset issues, you can use datasets to redesign
                your workflow automation and improve service requests as well as
                the future performance of ITSM.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of the integration power of collaborative ITSM
              </h2>
              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Collaborative ITSM ecosystem benefits"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If your ITSM is disparate, your teams and customers can have a
                disconnected user experience. A collaborative ITSM eliminates
                existing challenges with a legacy ITSM and builds a proactive
                ITSM, which ultimately helps you gain multiple benefits.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    The resolution of critical and common issues
                  </span>{" "}
                  is very fast with a collaborative ITSM ecosystem that Wokrativ
                  helps you build. Users can use MS Teams to leverage Generative
                  AI-powered self-service to solve everyday problems
                  autonomously. On the other hand, agents can use a shared live
                  inbox to manage tickets and use rich data to handle critical
                  issues.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Workativ helps increase the{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    productivity and efficiency of the service desk
                  </span>{" "}
                  dramatically. Users no longer need to find information
                  haphazardly. Generative AI provides relevant information from
                  your knowledge bases in a consolidated manner. Also, agents
                  can use AI-powered suggestions to send responses and save
                  time.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Workativ enables{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    seamless communications and coordination
                  </span>{" "}
                  for users with MS Teams. Users can communicate requests, get
                  updates, and fix critical issues with agents’ help. Also,
                  agents can double their creativity to handle critical issues
                  using Generative AI recommendations and suggestions.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Workativ ensures ITSM users can have{" "}
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    an improved user experience
                  </span>{" "}
                  by reducing the wait times for common and critical problems.
                  Generative AI search improves knowledge discovery, reducing
                  the time to leverage knowledge and address a domain-specific
                  issue, allowing users to come back to self-service and
                  increase adoption, which ultimately lowers agent workloads and
                  helps build a successful ITSM.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Embrace AI-driven collaborative ITSM with Workativ.
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Every company has some sort of IT assets. The bigger the IT
                infrastructure, the bigger the problem is. SMBs can also have
                pain points for the growing IT challenges. However, non-IT
                workflows are pretty concerning for ITSM leaders.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Legacy ITSM needs more features and automation to provide a
                collaborative infrastructure for ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ, with its conversational AI for MS Teams, provides a
                platform that elevates human creativity to tackle more
                challenging issues using Generative AI properties.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By improving communications, empowering human agents and users,
                and reducing MTTR, Workativ gives you the true essence of
                collaborative ITSM.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to leverage Workativ for a collaborative ITSM
                ecosystem,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today.{" "}
                </a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-automation-strategies"
              className="font-section-normal-text-testimonials"
            >
              Top ITSM Automation Strategies That Are Redefining IT Support
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023"
              className="font-section-normal-text-testimonials"
            >
              TOP ITSM Trends 2023: Preparing for the Future
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              ITSM Chatbot: Use Cases and Benefits Explained
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
